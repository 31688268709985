jQuery(document).ready(function() {
    jQuery('#rev_slider').show().revolution({
        dottedOverlay: 'none',
        delay: 5000,
        startwidth: 1920,
        startheight: 680,
        fullWidth: "on",
        fullScreen: "off",
        fullScreenOffsetContainer: "",

        // hideThumbs: 200,
        hideTimerBar:"on",
        // thumbWidth: 200,
        // thumbHeight: 50,
        // thumbAmount: 2,

        navigationType: 'bullet',
        navigationArrows: 'none',
        navigationStyle: 'none',

        touchenabled: 'on',
        onHoverStop: 'on',

        swipe_velocity: 0.7,
        swipe_min_touches: 1,
        swipe_max_touches: 1,
        drag_block_vertical: false,

        // spinner: 'spinner0',
        keyboardNavigation: 'on',

        //navigationHAlign: 'center',
        //navigationVAlign: 'bottom',
        //navigationHOffset: 0,
        //navigationVOffset: 20,

        // soloArrowLeftHalign: 'left',
        // soloArrowLeftValign: 'center',
        // soloArrowLeftHOffset: 20,
        // soloArrowLeftVOffset: 0,

        // soloArrowRightHalign: 'right',
        // soloArrowRightValign: 'center',
        // soloArrowRightHOffset: 20,
        // soloArrowRightVOffset: 0,

        shadow: 0,

        stopLoop: 'off',
        stopAfterLoops: -1,
        stopAtSlide: -1,

        shuffle: 'off',

        autoHeight: 'on',
        forceFullWidth: 'off',
        fullScreenAlignForce: 'off',
        minFullScreenHeight: 0,
        // hideNavDelayOnMobile: 1500,

        hideThumbsOnMobile: 'on',
        hideBulletsOnMobile: 'off',
        hideArrowsOnMobile: 'off',
        // hideThumbsUnderResolution: 0,

        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 767,
        startWithSlide: 0,
    });

});
$(document).ready(function() {
  // $('#news-slider .slider-items').owlCarousel({
  //     items: 4,
  //     itemsDesktop: [1024, 4],
  //     itemsDesktopSmall: [991, 3],
  //     itemsTablet: [768, 3],
  //     itemsMobile: [479, 2],
  //     navigation: !0,
  //     lazyLoad: true,
  //     navigationText: ['<a class="flex-prev"></a>', '<a class="flex-next"></a>'],
  //     slideSpeed: 500,
  //     pagination: !1
  // })
  $('#news-slider .owl-carousel').owlCarousel({
    loop:true,
    margin:20,
    nav:true,
    navContainer : '.news-owl-nav',
    navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
    lazyLoad:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:4
        }
    }
  })
});
$(function() {
    $(".equal-height").matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
    $("img.lazy").lazyload({
         effect : "fadeIn",
         threshold : 300,
    });
    /*  Best Seller Slider */
    $("#best-seller-slider .slider-items").owlCarousel({
        items: 4,
        itemsDesktop: [1024, 4],
        itemsDesktopSmall: [991, 3],
        itemsTablet: [768, 3],
        itemsMobile: [479, 2],
        navigation: !0,
        lazyLoad: true,
        navigationText: ['<a class="flex-prev"></a>', '<a class="flex-next"></a>'],
        slideSpeed: 500,
        pagination: !1
    });
    $(".item .item-info").matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

});